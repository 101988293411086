var Environment = getEnvironment();


function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "CFL") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
    const clientInfo = {
      "stage" : [
          { brand : "FF", country: 'MY', clientId: 'D777F0CD-81C3-4CA6-8D81-A569A19CA6E7' },
          { brand : "CF", country: 'MY',clientId: '3ECAD9C1-B1D3-482A-A549-BB40B1FD1D15' }
      ],
      "production": [
          { brand : "FF", country: 'MY', clientId: '77B6EA4C-5306-482A-9817-1991C5A385A8' },
          { brand : "CF", country: 'MY',clientId: '14F86156-D82B-4D7E-863C-ADFF52FEB208' }
      ]
    }
    const SSO = {
        clientInfo:clientInfo[platform],
        OPServer: platform === 'stage' ? 'https://id.uat.circuithq.com/' : 'https://id.circuithq.com/',
        redirect_uri: '/callback',
        scope: 'openid profile member-data offline_access',
        userInfoEndpoint: 'connect/userinfo',
        extra: {prompt: 'consent', access_type: 'offline'}
    }
    var _Environments = {
        production: { SSO, BASE_URL: 'https://api.wexer.com/', API_KEY: 'a0ddf4dc5b0b4b628e5a4c27b93c988e',JWPLAYER:'95riWD5s.js', API_SECRET: '711ff01003764889987158b329c7e6c0' },
        stage: { SSO, BASE_URL: 'https://stage.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
        development : { SSO, BASE_URL: 'https://ff-int.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'HofBP5zj.js', API_SECRET: '573572796441127398913495789868468206481' },
        test : { SSO, BASE_URL: "https://test.api.wexer.com/", API_KEY: '50703717552133197901571613251413029814', JWPLAYER:'HofBP5zj.js',API_SECRET: '51749401073929517144660310291806967949' },
        uat: { SSO, BASE_URL: 'https://uat.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
    
    }
    return _Environments[platform]
}
module.exports = Environment 
 